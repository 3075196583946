import React from 'react'
import { css } from '@emotion/react'
import { Link } from 'gatsby'
import color from '@/constants/color'
import { mq } from '@/components/media/media'

export const BreadcrumbNav = (props: {
  path: string
  breadcrumbs: string
}): JSX.Element => {
  const { path, breadcrumbs } = props
  const pathList = path.split('/').filter((str) => str !== '')
  const breadcrumbList = breadcrumbs.split('/').filter((str) => str !== '')

  return (
    <ul css={cssBreadcrumbList}>
      <li>
        <Link to="/">TOP</Link>
      </li>
      {pathList.map((path, index) => {
        const splicedPathList = [...[...pathList].splice(-3, index + 1)]
        const tempPath: string = splicedPathList.reduce((acc, curr) => {
          return `${acc}/${curr}`
        })
        return (
          <li key={index}>
            <span css={cssBreadcrumbSeparate}>/</span>
            {pathList.length - 1 !== index &&
              (!breadcrumbList[index].indexOf('GROUP') ? (
                breadcrumbList[index]
              ) : (
                <Link to={`/${tempPath}`}>{breadcrumbList[index]}</Link>
              ))}
            {pathList.length - 1 === index && (
              <Link to={`/${tempPath}`} aria-current="page">
                {breadcrumbList[index]}
              </Link>
            )}
          </li>
        )
      })}
    </ul>
  )
}

const cssBreadcrumbList = css`
  display: flex;
  font-size: 12px;
  color: ${color.black1};
  letter-spacing: 1.2px;
  ${mq.pc} {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
  }
`

const cssBreadcrumbSeparate = css`
  margin: 0 2px;
`
