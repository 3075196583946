import React from 'react'

import { ScalehackPageLayout } from '@/components/scalehack/scalehackPageLayout'
import { tempNewsList } from '@/constants/news/scalehack'
import { ScalehackHead } from '@/components/scalehack/scalehackHead'
import { NewsList } from '@/components/common/news'

export default function ScalehackNews(): JSX.Element {
  return (
    <>
      <ScalehackHead
        title="お知らせ| 株式会社Scalehack"
        url="group/scalehack/news"
      />
      <ScalehackPageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/SCALEHACK/NEWS"
        breadcrumbsPath="/group/scalehack//news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </ScalehackPageLayout>
    </>
  )
}
