import { NewsType } from '@/typedir/types'

export const tempNewsList: NewsType[] = [
  {
    date: '2022.08.02',
    text: 'Relicホールディングスの戦略子会社Scalehackが、Webマーケティングの大衆化を目指すベーシックと業務提携し、BtoBマーケティングを総合的に支援するオールインワンソリューションの提供を開始',
    url: 'https://prtimes.jp/main/html/rd/p/000000126.000016318.html'
  },
  {
    date: '2022.03.09',
    text: 'Relicホールディングスが、事業の急拡大や持続的成長のためのスケールアップスタジオを展開する戦略子会社「Scalehack」を設立',
    url: 'https://prtimes.jp/main/html/rd/p/000000105.000016318.html'
  }
]
